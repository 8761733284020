<template>
  <div>
    <template v-if="showMenu">
      <div class="cnt-title">
        <b @click="tabActive = 'root'">第二部分：申报正文 </b>
        <!-- <span v-if="tabActive !== 'root'">&nbsp;/&nbsp;{{ subTitle }}</span>
        <el-button
          size="mini"
          style="margin-left: 30px"
          type="success"
          v-if="tabActive !== 'root'"
          @click="tabActive = 'root'"
          >返回</el-button
        > -->
      </div>
      <el-card class="box-card">
        <div class="box-card-item pitem">
          <div class="title">四、申报人个人简历表</div>
          <div class="box-card-item-c">
            <div>1、主要学习经历</div>
            <div>
              <el-button type="primary" @click="handleEdit('xxjl')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai('printTestxxjl', '申报人个人简历表-主要学习经历')
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>2、主要工作经历</div>
            <div>
              <el-button type="primary" @click="handleEdit('gzjl')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai('printTestgzjl', '申报人个人简历表-主要工作经历')
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>3、主要社会兼职</div>
            <div>
              <el-button type="primary" @click="handleEdit('shjz')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai('printTestshjz', '申报人个人简历表-主要社会兼职')
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
        </div>
        <div class="box-card-item">
          <div>五、申报人所在单位产学研合作基本情况</div>
          <div>
            <el-button type="primary" @click="handleEdit('cxyhjjqk')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="
                xiazai('printTestcxyhjjqk', '申报人所在单位产学研合作基本情况')
              "
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
        <div class="box-card-item pitem">
          <div class="title">六、申报人在产学研合作创新突出事迹</div>
          <div class="box-card-item-c">
            <div>1、承担主要科研任务情况</div>
            <div>
              <el-button type="primary" @click="handleEdit('kyrw')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestkyrw',
                    '申报人在产学研合作创新突出事迹-承担主要科研任务情况'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>2、获得主要科研学术奖励情况</div>
            <div>
              <el-button type="primary" @click="handleEdit('kyxs')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestkyxs',
                    '申报人在产学研合作创新突出事迹-获得主要科研学术奖励情况'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>3、代表性论文</div>
            <div>
              <el-button type="primary" @click="handleEdit('dblw')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestdblw',
                    '申报人在产学研合作创新突出事迹-代表性论文'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>4、发明专利、软件著作权或动植物新品种等授权情况</div>
            <div>
              <el-button type="primary" @click="handleEdit('zlzz')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestzlzz',
                    '申报人在产学研合作创新突出事迹-发明专利、软件著作权或动植物新品种等授权情况'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>5、标准制定情况</div>
            <div>
              <el-button type="primary" @click="handleEdit('bzzd')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestbzzd',
                    '申报人在产学研合作创新突出事迹-标准制定情况'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>
              6、主要新产品（含新品种）/新装置（装备）/新工艺/新材料开发情况
            </div>
            <div>
              <el-button type="primary" @click="handleEdit('xcp')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestxcp',
                    '申报人在产学研合作创新突出事迹-主要新产品（含新品种_新装置（装备）_新工艺_新材料开发情况'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>7、 申请人在产学研合作中发挥重要作用的案例</div>
          </div>
          <div class="box-card-item-c box-card-item-t">
            <div>7-1、申请人在产学研合作中发挥重要作用的案例一</div>
            <div>
              <el-button type="primary" @click="handleEdit('zyal')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestzyal',
                    '申报人在产学研合作创新突出事迹-申请人在产学研合作中发挥重要作用的案例一'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c box-card-item-t">
            <div>7-2、申请人在产学研合作中发挥重要作用的案例二</div>
            <div>
              <el-button type="primary" @click="handleEdit('zyal2')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestzyal2',
                    '申报人在产学研合作创新突出事迹-申请人在产学研合作中发挥重要作用的案例二'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c box-card-item-t">
            <div>7-3、申请人在产学研合作中发挥重要作用的案例三</div>
            <div>
              <el-button type="primary" @click="handleEdit('zyal3')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestzyal3',
                    '申报人在产学研合作创新突出事迹-申请人在产学研合作中发挥重要作用的案例三'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
        </div>
      </el-card>
    </template>
    <div
      class="print-warp"
      :class="editType == 'xxjl' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestxxjl"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                四、申报人个人简历表
              </h3>
              <div style="text-align: left" class="fs24">
                1、主要学习经历表<span v-if="iss">（从大学填起，6项以内）</span>
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 25%">起止年月</td>
                  <td style="width: 32%">校（院）及系名称</td>
                  <td style="width: 20%">专业</td>
                  <td style="width: 15%">学位</td>
                </tr>
                <tr v-for="(item, index) in forms.study_data" :key="index">
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 25%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.year"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.year }}
                    </div>
                  </td>
                  <td style="width: 32%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.name"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.name }}
                    </div>
                  </td>
                  <td style="width: 20%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.major"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.major }}
                    </div>
                  </td>
                  <td style="width: 15%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.degree"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.degree }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              v-if="!readOnly"
              @click="
                handleSave(
                  3,
                  'study_data',
                  'printTestxxjl',
                  '申报人个人简历表-主要学习经历'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'gzjl' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestgzjl"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                四、申报人个人简历表
              </h3>
              <div style="text-align: left" class="fs24">
                2、主要工作经历表<span v-if="iss">（6项以内）</span>
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 25%">起止年月</td>
                  <td style="width: 42%">工作单位</td>
                  <td style="width: 25%">职务/职称</td>
                </tr>
                <tr v-for="(item, index) in forms.work_data" :key="index">
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 25%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.year"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.year }}
                    </div>
                  </td>
                  <td style="width: 42%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.dept"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.dept }}
                    </div>
                  </td>
                  <td style="width: 25%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.job"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.job }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              v-if="!readOnly"
              @click="
                handleSave(
                  4,
                  'work_data',
                  'printTestgzjl',
                  '申报人个人简历表-主要工作经历'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'shjz' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestshjz"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                四、申报人个人简历表
              </h3>
              <div style="text-align: left" class="fs24">
                3、主要社会兼职表<span v-if="iss">（5项以内）</span>
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 25%">起止年月</td>
                  <td style="width: 42%">工作单位</td>
                  <td style="width: 25%">职务</td>
                </tr>
                <tr
                  v-for="(item, index) in forms.part_time_job_data"
                  :key="index"
                >
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 25%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.year"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.year }}
                    </div>
                  </td>
                  <td style="width: 42%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.unit"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.unit }}
                    </div>
                  </td>
                  <td style="width: 25%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.job"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.job }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              v-if="!readOnly"
              @click="
                handleSave(
                  5,
                  'part_time_job_data',
                  'printTestshjz',
                  '申报人个人简历表-主要社会兼职'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'cxyhjjqk' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestcxyhjjqk"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                五、申报人所在单位产学研合作基本情况表<span v-if="iss"
                  >（800字以内）</span
                >
              </h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 100%">
                    <div
                      v-if="iss"
                      style="width: 100%; height: 1500px; position: relative"
                    >
                      <!-- <div
                        style="
                          position: absolute;
                          bottom: 10px;
                          right: 10px;
                          font-size: 12px;
                          color: #999;
                        "
                      >
                        {{ forms.unit_cooperate_situation_data?.length }}/800
                      </div> -->
                      <textarea
                        type="text"
                        style="
                          width: 100%;
                          height: 100%;
                          box-sizing: border-box;
                          padding: 10px;
                          font-size: 28px;
                          resize: none;
                          border: none;
                          outline: none;
                        "
                        v-model="forms.unit_cooperate_situation_data"
                      />
                    </div>
                    <pre v-else><div
                        style="
                          text-align: left;
                          box-sizing: border-box;
                          padding: 15px;
                          width: 100%;
                          height: 1500px;
                          line-height: 1.5;
                          white-space:pre-wrap;
                        "
                        v-html="forms.unit_cooperate_situation_data"
                      ></div></pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              v-if="!readOnly"
              @click="
                handleSave(
                  6,
                  'unit_cooperate_situation_data',
                  'printTestcxyhjjqk',
                  '申报人所在单位产学研合作基本情况'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'kyrw' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestkyrw"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                六、申报人在产学研合作创新工作中的突出事迹表
              </h3>
              <div style="text-align: left" class="fs24">
                1、承担主要科研任务情况表<span v-if="iss">（5项以内）</span>
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 22%">项目（课题/任务）名称</td>
                  <td style="width: 18%">立项编号</td>
                  <td style="width: 10%">经费(万元)</td>
                  <td style="width: 12%">起止年月</td>
                  <td style="width: 10%">项目来源</td>
                  <td style="width: 10%">计划名称</td>
                  <td style="width: 10%">担任角色</td>
                </tr>
                <tr
                  v-for="(
                    item, index
                  ) in forms.cooperative_innovation_project_matter_data"
                  :key="index"
                >
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 22%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.name"
                      :key="index"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.name }}
                    </div>
                  </td>
                  <td style="width: 18%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.prjNum"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.prjNum }}
                    </div>
                  </td>
                  <td style="width: 10%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.funds"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.funds }}
                    </div>
                  </td>
                  <td style="width: 12%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.year"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.year }}
                    </div>
                  </td>
                  <td style="width: 10%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.projectSource"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.projectSource }}
                    </div>
                  </td>
                  <td style="width: 10%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.planName"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.planName }}
                    </div>
                  </td>
                  <td style="width: 10%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.role"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.role }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              v-if="!readOnly"
              @click="
                handleSave(
                  7,
                  'cooperative_innovation_project_matter_data',
                  'printTestkyrw',
                  '申报人在产学研合作创新突出事迹-承担主要科研任务情况'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'kyxs' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestkyxs"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                六、申报人在产学研合作创新工作中的突出事迹表
              </h3>
              <div style="text-align: left" class="fs24">
                2、获得主要科研学术奖励情况表<span v-if="iss">（5项以内）</span>
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 26%">获奖项目名称</td>
                  <td style="width: 26%">奖励名称</td>
                  <td style="width: 10%">等级</td>
                  <td style="width: 10%">排序</td>
                  <td style="width: 10%">获奖时间</td>
                  <td style="width: 10%">授予机构</td>
                </tr>
                <tr
                  v-for="(
                    item, index
                  ) in forms.research_academic_awards_situation_data"
                  :key="index"
                >
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 26%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.awardPrjName"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.awardPrjName }}
                    </div>
                  </td>
                  <td style="width: 26%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.rewardName"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.rewardName }}
                    </div>
                  </td>
                  <td style="width: 10%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.grade"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.grade }}
                    </div>
                  </td>
                  <td style="width: 10%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.sort"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.sort }}
                    </div>
                  </td>
                  <td style="width: 10%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.awardTime"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.awardTime }}
                    </div>
                  </td>
                  <td style="width: 10%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.grantingInstitution"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.grantingInstitution }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              v-if="!readOnly"
              @click="
                handleSave(
                  8,
                  'research_academic_awards_situation_data',
                  'printTestkyxs',
                  '申报人在产学研合作创新突出事迹-获得主要科研学术奖励情况'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'dblw' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestdblw"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                六、申报人在产学研合作创新工作中的突出事迹表
              </h3>
              <div style="text-align: left" class="fs24">
                3、代表性论文<span v-if="iss"
                  >（“第一作者”或“通讯作者”的论文）（不超过5篇）</span
                >
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 16%">论文题目</td>
                  <td style="width: 16%">所有作者（通讯作者请标注*）</td>
                  <td style="width: 16%">期刊名称</td>
                  <td style="width: 16%">年份、卷期及页码</td>
                  <td style="width: 13%">被SCI、EI、ISTP收录情况</td>
                  <td style="width: 4%">影响因子</td>
                  <td style="width: 11%">他引次数</td>
                </tr>
                <tr
                  v-for="(item, index) in forms.representative_papers_data"
                  :key="index"
                >
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 16%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.title"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.title }}
                    </div>
                  </td>
                  <td style="width: 16%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.author"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.author }}
                    </div>
                  </td>
                  <td style="width: 16%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.journalName"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.journalName }}
                    </div>
                  </td>
                  <td style="width: 16%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.year"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.year }}
                    </div>
                  </td>
                  <td style="width: 13%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.sci"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.sci }}
                    </div>
                  </td>
                  <td style="width: 4%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.impactfactors"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.impactfactors }}
                    </div>
                  </td>
                  <td style="width: 11%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.times"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.times }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              v-if="!readOnly"
              @click="
                handleSave(
                  9,
                  'representative_papers_data',
                  'printTestdblw',
                  '申报人在产学研合作创新突出事迹-代表性论文'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'zlzz' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestzlzz"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                六、申报人在产学研合作创新工作中的突出事迹表
              </h3>
              <div style="text-align: left" class="fs24">
                4、发明专利、软件著作权或动植物新品种等授权情况<span v-if="iss"
                  >（不超过10个）</span
                >
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 22%">名称</td>
                  <td style="width: 13%">授权号</td>
                  <td style="width: 15%">类别</td>
                  <td style="width: 14%">排序</td>
                  <td style="width: 13%">授权时间</td>
                  <td style="width: 15%">授权国别或组织</td>
                </tr>
                <tr
                  v-for="(item, index) in forms.patent_works_empower_data"
                  :key="index"
                >
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 22%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.name"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.name }}
                    </div>
                  </td>
                  <td style="width: 13%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.authNum"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.authNum }}
                    </div>
                  </td>
                  <td style="width: 15%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.type"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.type }}
                    </div>
                  </td>
                  <td style="width: 14%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.sort"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.sort }}
                    </div>
                  </td>
                  <td style="width: 13%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.time"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.time }}
                    </div>
                  </td>
                  <td style="width: 15%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.org"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.org }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              v-if="!readOnly"
              @click="
                handleSave(
                  10,
                  'patent_works_empower_data',
                  'printTestzlzz',
                  '申报人在产学研合作创新突出事迹-发明专利、软件著作权或动植物新品种等授权情况'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'bzzd' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestbzzd"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                六、申报人在产学研合作创新工作中的突出事迹表
              </h3>
              <div style="text-align: left" class="fs24">
                5、 标准制定情况<span v-if="iss">（不超过5个）</span>
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 20%">标准号</td>
                  <td style="width: 27%">标准名称</td>
                  <td style="width: 15%">类别</td>
                  <td style="width: 15%">颁布/修订时间</td>
                  <td style="width: 15%">本人排序</td>
                </tr>
                <tr
                  v-for="(
                    item, index
                  ) in forms.standard_development_status_data"
                  :key="index"
                >
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 20%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.standardNumber"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.standardNumber }}
                    </div>
                  </td>
                  <td style="width: 27%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.name"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.name }}
                    </div>
                  </td>
                  <td style="width: 15%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.type"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.type }}
                    </div>
                  </td>
                  <td style="width: 15%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.revisionTime"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.revisionTime }}
                    </div>
                  </td>
                  <td style="width: 15%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.sort"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.sort }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              v-if="!readOnly"
              @click="
                handleSave(
                  11,
                  'standard_development_status_data',
                  'printTestbzzd',
                  '申报人在产学研合作创新突出事迹-标准制定情况'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'xcp' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestxcp"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                六、申报人在产学研合作创新工作中的突出事迹表
              </h3>
              <div style="text-align: left" class="fs24">
                6、主要新产品<span v-if="iss"
                  >（含新品种）/新装置（装备）/新工艺/新材料开发情况（不超过5个）</span
                >
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 36%">名称</td>
                  <td style="width: 28%">创新性</td>
                  <td style="width: 28%">经济及社会效益</td>
                </tr>
                <tr
                  v-for="(item, index) in forms.development_data"
                  :key="index"
                >
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 36%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.name"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.name }}
                    </div>
                  </td>
                  <td style="width: 28%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.innovation"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.innovation }}
                    </div>
                  </td>
                  <td style="width: 28%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.socialBenefits"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.socialBenefits }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              v-if="!readOnly"
              @click="
                handleSave(
                  12,
                  'development_data',
                  'printTestxcp',
                  '申报人在产学研合作创新突出事迹-主要新产品（含新品种_新装置（装备）_新工艺_新材料开发情况'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'zyal' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestzyal"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                六、申报人在产学研合作创新工作中的突出事迹表
              </h3>
              <div style="text-align: left" class="fs24">
                7、申请人在产学研合作中发挥重要作用的案例一
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 100%">
                    <div
                      v-if="iss"
                      style="width: 100%; height: 1400px; position: relative"
                    >
                      <!-- <div
                        style="
                          position: absolute;
                          bottom: 10px;
                          right: 10px;
                          font-size: 12px;
                          color: #999;
                        "
                      >
                        {{ forms.cooperation_important_case_dataA?.length }}/1000
                      </div> -->
                      <textarea
                        type="text"
                        style="
                          width: 100%;
                          height: 100%;
                          box-sizing: border-box;
                          padding: 10px;
                          font-size: 28px;
                          resize: none;
                          border: none;
                          outline: none;
                        "
                        v-model="forms.cooperation_important_case_dataA"
                      />
                    </div>
                    <pre v-else><div
                        style="
                          text-align: left;
                          box-sizing: border-box;
                          padding: 15px;
                          width: 100%;
                          height: 1400px;
                          line-height: 1.5;
                             white-space:pre-wrap;
                        "
                        v-html="forms.cooperation_important_case_dataA"
                      ></div></pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              v-if="!readOnly"
              type="primary"
              :loading="btnLoading"
              @click="
                handleSave(
                  13,
                  'cooperation_important_case_data',
                  'printTestzyal',
                  '申报人在产学研合作创新突出事迹-申请人在产学研合作中发挥重要作用的案例一'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="print-warp"
      :class="editType == 'zyal2' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestzyal2"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                六、申报人在产学研合作创新工作中的突出事迹表
              </h3>
              <div style="text-align: left" class="fs24">
                7、申请人在产学研合作中发挥重要作用的案例二
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 100%">
                    <div
                      v-if="iss"
                      style="width: 100%; height: 1400px; position: relative"
                    >
                      <!-- <div
                        style="
                          position: absolute;
                          bottom: 10px;
                          right: 10px;
                          font-size: 12px;
                          color: #999;
                        "
                      >
                        {{ forms.cooperation_important_case_dataB?.length }}/1000
                      </div> -->
                      <textarea
                        type="text"
                        style="
                          width: 100%;
                          height: 100%;
                          box-sizing: border-box;
                          padding: 10px;
                          font-size: 28px;
                          resize: none;
                          border: none;
                          outline: none;
                        "
                        v-model="forms.cooperation_important_case_dataB"
                      />
                    </div>
                    <pre v-else><div
                        style="
                          text-align: left;
                          box-sizing: border-box;
                          padding: 15px;
                          width: 100%;
                          height: 1400px;
                          line-height: 1.5;
                             white-space:pre-wrap;
                        "
                        v-html="forms.cooperation_important_case_dataB"
                      ></div></pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              v-if="!readOnly"
              type="primary"
              :loading="btnLoading"
              @click="
                handleSave(
                  16,
                  'type_value_16',
                  'printTestzyal2',
                  '申报人在产学研合作创新突出事迹-申请人在产学研合作中发挥重要作用的案例二'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'zyal3' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestzyal3"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                六、申报人在产学研合作创新工作中的突出事迹表
              </h3>
              <div style="text-align: left" class="fs24">
                7、申请人在产学研合作中发挥重要作用的案例三
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 100%">
                    <div
                      v-if="iss"
                      style="width: 100%; height: 1400px; position: relative"
                    >
                      <!-- <div
                        style="
                          position: absolute;
                          bottom: 10px;
                          right: 10px;
                          font-size: 12px;
                          color: #999;
                        "
                      >
                        {{ forms.cooperation_important_case_dataA?.length }}/1000
                      </div> -->
                      <textarea
                        type="text"
                        style="
                          width: 100%;
                          height: 100%;
                          box-sizing: border-box;
                          padding: 10px;
                          font-size: 28px;
                          resize: none;
                          border: none;
                          outline: none;
                        "
                        v-model="forms.cooperation_important_case_dataC"
                      />
                    </div>
                    <pre v-else><div
                        style="
                          text-align: left;
                          box-sizing: border-box;
                          padding: 15px;
                          width: 100%;
                          height: 1400px;
                          line-height: 1.5;
                             white-space:pre-wrap;
                        "
                        v-html="forms.cooperation_important_case_dataC"
                      ></div></pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              v-if="!readOnly"
              type="primary"
              :loading="btnLoading"
              @click="
                handleSave(
                  17,
                  'type_value_17',
                  'printTestzyal3',
                  '申报人在产学研合作创新突出事迹-申请人在产学研合作中发挥重要作用的案例三'
                )
              "
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { innovation_will, user_file, upload_pdf } from "../../axios/api";
export default {
  props: {
    fromData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fromData: {
      handler: function (newUser) {
        this.forms = JSON.parse(JSON.stringify(newUser));
      },
      deep: true, // 设置 deep 选项为 true，实现深度监听
    },
  },
  mounted() {
    this.forms = JSON.parse(JSON.stringify(this.fromData));
    if (this.readOnly) {
      this.iss = false;
    }
  },
  data() {
    return {
      tabActive: "root", // 根目录
      subTitle: "", // 副标题
      iss: true,
      showMenu: true, // 是否展示目录
      editType: "", // 编辑类型
      forms: {},
      btnLoading: false,
    };
  },
  methods: {
    changeMenu(type, name) {
      console.log(type, name);
      this.tabActive = type;
      this.subTitle = name;
    },
    handleEdit(type) {
      this.showMenu = false;
      this.editType = type;
    },
    handleBack() {
      this.showMenu = true;
      this.editType = "";
      this.forms = { ...this.fromData };
    },
    xiazai(idName, fileName) {
      this.iss = !this.iss;
      const loading = this.$loading({
        lock: true,
        text: "玩命加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector(`#${idName}`),
          "创新人物奖",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          loading.close();
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `https://2024pj.ciur.org.cn/common/2022/download_file?path=${res.data.url}&file_name=${fileName}`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    handleSave(type, key, idName, typeName) {
      if (this.btnLoading) return;
      this.iss = !this.iss;
      this.btnLoading = true;
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector(`#${idName}`),
          "创新人物奖",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form)
            .then((res) => {
              let params = {
                user_split_id: this.forms.id, //申报记录ID2
                path_url: res.data.url, //url
                type: type + "",
                token: sessionStorage.getItem("Token"),
                type_name: typeName,
              };
              user_file(params).then((res) => {
                let reqParams = {
                  token: sessionStorage.getItem("Token"),
                  id: this.forms.id + "",
                  type: type + "",
                };
                if (key === "unit_cooperate_situation_data") {
                  reqParams[key] = this.forms[key];
                } else if (key === "cooperation_important_case_data") {
                  let newArr = [
                    this.forms.cooperation_important_case_dataA || "",
                  ];
                  reqParams[key] = JSON.stringify(newArr);
                } else if (key === "type_value_16") {
                  reqParams[key] = this.forms.cooperation_important_case_dataB;
                } else if (key === "type_value_17") {
                  reqParams[key] = this.forms.cooperation_important_case_dataC;
                } else {
                  reqParams[key] = JSON.stringify(this.forms[key]);
                }
                if (res.data.msg == "成功") {
                  innovation_will(reqParams)
                    .then((res) => {
                      if (res.data.code == 200) {
                        this.$message({
                          message: "保存成功",
                          type: "success",
                        });
                        this.$emit("update:fromData", this.forms);
                        // this.num = 1;
                      } else {
                        this.$message.error(res.data.msg);
                      }
                    })
                    .finally(() => {
                      this.btnLoading = false;
                    });
                } else {
                  this.btnLoading = false;
                  alert("保存失败，请重新下一步");
                }
              });
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.cnt-title {
  font-size: 30px;
  margin: 80px 5% 80px 5%;
  display: flex;
  align-items: center;
  b {
    cursor: pointer;
  }
  span {
    color: #999;
  }
}
.box-card {
  width: 65%;
  margin: auto;
  margin-bottom: 100px;
  ::v-deep .el-card__body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .box-card-item {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    font-size: 26px;
    font-weight: 500;
    &.pitem {
      flex-direction: column;
      width: 100%;
      .title {
        width: 100%;
      }
    }
    .box-card-item-c {
      margin-left: 50px;
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dbdbdb;
      width: calc(100% - 50px);
      font-weight: 500;

      &:nth-child(1) {
        margin-top: 10px;
      }
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
    .box-card-item-t {
      margin-left: 100px;
      width: calc(100% - 100px);
    }
  }
}
.cnt-warp {
  overflow: hidden;
  width: 90%;
  margin: auto;
  .cnt-item {
    width: 18%;
    margin-right: 2.5%;
    margin-bottom: 2%;
    float: left;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .cnt-item-top {
      width: 100%;
      padding-top: 133.33%;
      height: 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .cnt-item-bottom {
      padding: 30px;
      display: flex;
      justify-content: space-around;
    }
  }
}
.cnt-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  width: 90%;
  color: #ffe400;
  letter-spacing: 2px;
}
</style>
<style scoped>
.print-warp {
  position: absolute;
  left: -10000px;
  top: -10000px;
  width: 100%;
}
.print-warp.show {
  position: relative;
  top: 0;
  left: 0;
}
.fengmiannew {
  width: 1200px;
  height: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 300px;
  align-items: center;
}
.fengmiannew > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmiannew > div:nth-child(1) > div {
  font-size: 40px;
}
.fengmiannew > div:nth-child(2) > div {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.fengmiannew > div:nth-child(2) > div > div:nth-child(1) {
  width: 240px;
  font-size: 22px;
}
.fengmiannew > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  font-size: 24px;
  border: 0px;
  border-bottom: 1px solid #999;
}
.shenMain {
  width: 97%;
  /* height: calc(100% - 100px); */
  height: auto;
  margin: 0 auto;
}
.shenMain .bh {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.shenMain table {
  width: 1300px;
  margin: 20px 0;
  border-collapse: collapse;
  border-color: #000;
}
.shenMain tr {
  height: 75px;
}
.shenMain td {
  width: 150px;
  text-align: center;
  font-size: 24px;
}
.tabls input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 24px;
  width: 90px;
  min-height: 32px !important;
  line-height: 32px;
}
.tabls_bom {
  width: 100%;
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  justify-content: space-around;
}
.tabls_bom1 {
  width: 400px;
  display: flex;
  position: fixed;
  bottom: 20px;
  padding: 24px;
  left: 47%;
  box-sizing: border-box;
  justify-content: space-between;
}
.tabls_bom1 > div {
  width: 40%;
}
/* .tabls {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.tabls > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zunshou {
  display: flex;
  flex-direction: column;
  line-height: 1.8;
}
.qz-warp {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
}
.dwwarp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dwwarp input {
  width: auto;
}
input[type="checkbox"] {
  position: relative;
  width: 18px;
  height: 18px;
}
.table-textarea {
  width: 100%;
  outline: none;
  box-sizing: border-box;
}
.feng_btn {
  position: relative;
  z-index: 3;
}
.fs24 {
  font-size: 24px;
}
</style>
