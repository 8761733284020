<template>
  <div>
    <template v-if="showMenu">
      <div class="cnt-title">
        <b @click="tabActive = 'root'">第二部分：申报正文 </b>
        <!-- <span v-if="tabActive !== 'root'">&nbsp;/&nbsp;{{ subTitle }}</span>
        <el-button
          size="mini"
          style="margin-left: 30px"
          type="success"
          v-if="tabActive !== 'root'"
          @click="tabActive = 'root'"
          >返回</el-button
        > -->
      </div>
      <el-card class="box-card">
        <div class="box-card-item">
          <div>七、申报项目科技创新及产学研合作情况</div>
          <div>
            <el-button @click="handleEdit('kjcx')" type="primary">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
          </div>
        </div>
        <!-- <div class="box-card-item">
          <div>八、申报项目客观评价表</div>
          <div>
            <el-button type="primary" @click="handleEdit('kgpj')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTestkgpj', '申报项目客观评价表')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>

        <div class="box-card-item">
          <div>九、申报项目产学研合作情况表</div>
          <div>
            <el-button type="primary" @click="handleEdit('hzqk')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTesthzqk', '申报项目产学研合作情况表')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
        <div class="box-card-item pitem">
          <div class="title">十、应用情况和效益表</div>
          <div class="box-card-item-c">
            <div>1、 推广应用情况</div>
            <div>
              <el-button type="primary" @click="handleEdit('tgyy')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai('printTesttgyy', '应用情况和效益表-推广应用情况')
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>2、近三年申报项目主要应用单位目录</div>
            <div>
              <el-button type="primary" @click="handleEdit('dwml')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestdwml',
                    '应用情况和效益表-近三年申报项目主要应用单位目录'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
          <div class="box-card-item-c">
            <div>3、近三年申报项目主要应用单位经济效益、社会效益</div>
            <div>
              <el-button type="primary" @click="handleEdit('xy')">{{
                readOnly ? "查看" : "编辑"
              }}</el-button>
              <el-button
                type="primary"
                @click="
                  xiazai(
                    'printTestxy',
                    '应用情况和效益表-近三年申报项目主要应用单位经济效益、社会效益'
                  )
                "
                v-if="!readOnly"
                >下载</el-button
              >
            </div>
          </div>
        </div> -->

        <div class="box-card-item">
          <div>八、申报项目所获知识产权证明目录表</div>
          <div>
            <el-button type="primary" @click="handleEdit('cqzm')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="
                xiazai('printTestcqzm', '申报项目所获知识产权证明目录表')
              "
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
        <div class="box-card-item">
          <div>九、申报项目获得科研奖励情况表</div>
          <div>
            <el-button type="primary" @click="handleEdit('kyjl')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTestkyjl', '申报项目获得科研奖励情况表')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
        <div class="box-card-item">
          <div>十、申报项目代表性论文表</div>
          <div>
            <el-button type="primary" @click="handleEdit('dbxlw')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTestdbxlw', '申报项目代表性论文表')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
        <div class="box-card-item">
          <div>十一、申报项目标准制定情况表</div>
          <div>
            <el-button type="primary" @click="handleEdit('zdqk')">{{
              readOnly ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="primary"
              @click="xiazai('printTestzdqk', '申报项目标准制定情况表')"
              v-if="!readOnly"
              >下载</el-button
            >
          </div>
        </div>
      </el-card>
    </template>
    <div
      class="print-warp"
      :class="editType == 'kjcx' && !showMenu ? 'show' : ''"
    >
      <div class="shangchuan">
        <div>
          <template v-if="readOnly">
            <div style="margin-bottom: 30px">申报项目科技创新及产学研合作情况PDF文档</div>
          </template>
          <template v-else>
            <el-button @click="xiazaiModel" style="margin-bottom: 20px"
              >下载模板</el-button
            >
            <div style="margin-bottom: 20px">
              请先下载模板，并依照模板填写内容，填写后，同时生成pdf文件上传至平台。
            </div>
            <el-upload
              style="display: flex; flex-direction: column; align-items: center"
              class="upload-demo"
              ref="upload"
              action
              :http-request="uploadFile"
              :on-change="handleChange"
              :show-file-list="false"
            >
              <el-button type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传PDF文档</div>
            </el-upload>
          </template>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <el-button type="primary" @click="handleBack">返回</el-button>
            <template v-if="fileList.length != 0">
              <el-button type="primary" @click="ylan">预览</el-button>
              <el-button
                @click="handleSavePdf(20)"
                type="primary"
                v-if="!readOnly"
                >保存</el-button
              >
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'kgpj' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestkgpj"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                八、申报项目客观评价表<span v-if="iss">（表格填写）</span>
              </h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td>
                    <div style="text-align: left; padding: 10px 10px 20px">
                      1、 验收报告、检测报告<br />
                      2、 查新报告<br />
                      3、 专家评价意见、他引<br />
                      4、 其他
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <el-input
                      class="yy-text"
                      type="textarea"
                      :autosize="{ minRows: 50, maxRows: 50 }"
                      placeholder=" "
                      v-model="forms.acceptance_report"
                      v-if="iss"
                    >
                    </el-input>
                    <pre v-else><div
                        style="text-align: left; padding: 5px 5px 5px 10px; min-height: 1300px;white-space:pre-wrap;border-bottom:1px solid #999"
                        v-html="forms.acceptance_report"></div></pre>
                    <!-- <div style="text-align: left; margin: 10px 10px 20px">
                      2、查新报告：
                    </div>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 6 }"
                      placeholder=" "
                      v-model="forms.novelty_search_report"
                      v-if="iss"
                    >
                    </el-input>
                    <pre v-else> <div
                        style="text-align: left; padding: 5px; min-height: 350px;white-space:pre-wrap;border-bottom:1px solid #999"
                        v-html="forms.novelty_search_report"></div></pre>
                    <div style="text-align: left; margin: 10px 10px 20px">
                      3、专家评价意见、他引：
                    </div>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 6 }"
                      placeholder=" "
                      v-model="forms.expert_evaluation_opinions"
                      v-if="iss"
                    >
                    </el-input>
                    <pre v-else><div
                          style="text-align: left; padding: 5px; min-height: 350px;white-space:pre-wrap;border-bottom:1px solid #999"
                          v-html="forms.expert_evaluation_opinions"></div></pre>
                    <div style="text-align: left; margin: 10px 10px 20px">
                      4、其他：
                    </div>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 6 }"
                      placeholder=" "
                      v-model="forms.other"
                      v-if="iss"
                    >
                    </el-input>
                    <pre v-else><div
                          style="text-align: left; padding: 5px; min-height: 350px;white-space:pre-wrap;"
                          v-html="forms.other"></div>
                      </pre> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="handleSave(21, 'printTestkgpj', '申报项目客观评价表')"
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'hzqk' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTesthzqk"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                九、申报项目产学研合作情况表<span v-if="iss">（表格填写）</span>
              </h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td>
                    <div style="text-align: left; margin: 10px 10px 20px 0">
                      1、申报项目的产学研合作情况：（合作的主要单位、主要目标、内容、合作模式、合作机制、利益分配机制等详细阐述）
                    </div>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 12, maxRows: 12 }"
                      placeholder=" "
                      v-model="forms.report_cooperate_situation"
                      v-if="iss"
                      maxlength="800"
                      show-word-limit
                    >
                    </el-input>
                    <pre
                      v-else
                    ><div  style="text-align: left; padding: 5px; min-height: 650px;white-space:pre-wrap;" v-html="forms.report_cooperate_situation">
                      </div></pre>

                    <div style="text-align: left; margin: 10px 10px 20px">
                      2、申报单位产学研合作在组织创新、管理创新、机制创新等方面的体会和经验：
                    </div>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 12, maxRows: 12 }"
                      placeholder=" "
                      v-model="forms.report_unit_cooperate_situation_experience"
                      v-if="iss"
                      maxlength="800"
                      show-word-limit
                    >
                    </el-input>
                    <pre
                      v-else
                    ><div  style="text-align: left; padding: 5px; min-height: 650px;white-space:pre-wrap;" v-html="forms.report_unit_cooperate_situation_experience">
                      </div></pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="
                handleSave(22, 'printTesthzqk', '申报项目产学研合作情况表')
              "
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'tgyy' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTesttgyy"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                十、应用情况和效益表<span v-if="iss">（表格填写）</span>
              </h3>
              <div style="text-align: left;" class="fs24">
                1、推广应用情况表：
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="min-height: 1400px">
                    <el-input
                      class="yy-text"
                      type="textarea"
                      :autosize="{ minRows: 50, maxRows: 50 }"
                      placeholder=" "
                      v-model="forms.promotion_application_situation"
                      v-if="iss"
                    >
                    </el-input>
                    <pre v-else><div
                          style="text-align: left; padding: 5px 5px 5px 10px; min-height: 1400px;white-space:pre-wrap;"
                          v-html="forms.promotion_application_situation"
                        ></div></pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="
                handleSave(23, 'printTesttgyy', '应用情况和效益表-推广应用情况')
              "
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'dwml' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestdwml"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                十、应用情况和效益表<span v-if="iss">（表格填写）</span>
              </h3>
              <div style="text-align: left;" class="fs24">
                2、近三年申报项目主要应用单位目录表<span v-if="iss"
                  >（截止到2023年12月）（限10份）
                  （不仅限于申报项目完成单位，自行添加行）</span
                >
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 24%">应用单位名称</td>
                  <td style="width: 18%">应用起始时间</td>
                  <td style="width: 24%">应用单位联系人及电话</td>
                  <td style="width: 18%">使用本项目产生的经济效益（万元）</td>
                  <td style="width: 8%">已提交应用证明（√）</td>
                </tr>
                <tr
                  v-for="(item, index) in forms.app_project_three_years_data"
                  :key="index"
                >
                  <td style="width: 8%">{{ index + 1 }}</td>
                  <td style="width: 24%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.unitName"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.unitName }}
                    </div>
                  </td>
                  <td style="width: 18%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.times"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.times }}
                    </div>
                  </td>
                  <td style="width: 24%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.lxrDh"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.lxrDh }}
                    </div>
                  </td>
                  <td style="width: 18%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.economic"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.economic }}
                    </div>
                  </td>
                  <td style="width: 8%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.prove"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.prove }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="
                handleSave(
                  24,
                  'printTestdwml',
                  '应用情况和效益表-近三年申报项目主要应用单位目录'
                )
              "
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'xy' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestxy"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                十、应用情况和效益表<span v-if="iss">（表格填写）</span>
              </h3>
              <div style="text-align: left;" class="fs24">
                3、近三年申报项目主要应用单位经济效益、社会效益表
                <span style="float: right"> 单位：万元</span>
              </div>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td colspan="2">项目总投资额</td>
                  <td colspan="2">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="
                        forms.app_project_three_years_benefits_data.amount
                      "
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: center; padding: 5px" v-else>
                      {{ forms.app_project_three_years_benefits_data.amount }}
                    </div>
                  </td>
                  <td colspan="2">回收年</td>
                  <td colspan="2">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="forms.app_project_three_years_benefits_data.year"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: center; padding: 5px" v-else>
                      {{ forms.app_project_three_years_benefits_data.year }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">年份</td>
                  <td colspan="3">新增销售额</td>
                  <td colspan="3">新增利润</td>
                </tr>
                <tr
                  v-for="(item, index) in forms
                    .app_project_three_years_benefits_data.sales"
                  :key="index"
                >
                  <td colspan="2">{{ item.year }}年</td>
                  <td colspan="3">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.salesRevenue"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: center; padding: 5px" v-else>
                      {{ item.salesRevenue }}
                    </div>
                  </td>
                  <td colspan="3">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.newProfit"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: center; padding: 5px" v-else>
                      {{ item.newProfit }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">累计</td>
                  <td colspan="3">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="
                        forms.app_project_three_years_benefits_data
                          .totalSalesRevenue
                      "
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: center; padding: 5px" v-else>
                      {{
                        forms.app_project_three_years_benefits_data
                          .totalSalesRevenue
                      }}
                    </div>
                  </td>
                  <td colspan="3">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="
                        forms.app_project_three_years_benefits_data
                          .totalNewProfit
                      "
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: center; padding: 5px" v-else>
                      {{
                        forms.app_project_three_years_benefits_data
                          .totalNewProfit
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="8">
                    <div style="overflow: hidden" :class="!iss ? 'p-xy-h' : ''">
                      <div style="text-align: left; margin: 10px 10px 20px">
                        经济效益指标的有关说明：（与提供应用证明数据累计相符）
                      </div>
                      <el-input
                        class="xy-top"
                        type="textarea"
                        placeholder=" "
                        v-model="
                          forms.app_project_three_years_benefits_data
                            .economicBenefits
                        "
                        v-if="iss"
                      >
                      </el-input>
                      <pre v-else><div
                            style="text-align: left; padding: 5px; min-height: 300px;white-space:pre-wrap;"
                            v-html="forms.app_project_three_years_benefits_data
                                .economicBenefits"></div></pre>
                      <div style="text-align: left; margin: 10px 10px 20px">
                        社会效益情况：
                      </div>
                      <el-input
                        class="xy-bottom"
                        type="textarea"
                        placeholder=" "
                        v-model="
                          forms.app_project_three_years_benefits_data
                            .socialBenefits
                        "
                        v-if="iss"
                      >
                      </el-input>
                      <pre v-else><div
                              style="text-align: left; padding: 5px; min-height: 300px;white-space:pre-wrap;"
                              v-html="forms.app_project_three_years_benefits_data.socialBenefits"></div></pre>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="
                handleSave(
                  25,
                  'printTestxy',
                  '应用情况和效益表-近三年申报项目主要应用单位经济效益、社会效益'
                )
              "
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'cqzm' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestcqzm"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                八、申报项目所获知识产权证明目录表<span v-if="iss"
                  >：（不超过10个）</span
                >
              </h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td>知识产权类别和名称</td>
                  <td>国家（地区）</td>
                  <td>授权号</td>
                  <td>授权日期</td>
                  <td>证书编号</td>
                  <td>权利人</td>
                  <td style="width: 8%">发明人</td>
                  <td style="width: 8%">发明专利有效状态</td>
                </tr>
                <tr
                  v-for="(
                    item, index
                  ) in forms.catalogue_intellectual_property_certificates_data"
                  :key="index"
                >
                  <td style="width: 8%">{{ index + 1 }}</td>
                  <td>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.type"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.type }}
                    </div>
                  </td>
                  <td>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.country"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.country }}
                    </div>
                  </td>
                  <td>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.num"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.num }}
                    </div>
                  </td>
                  <td>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.date"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.date }}
                    </div>
                  </td>
                  <td>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.certificateNumber"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.certificateNumber }}
                    </div>
                  </td>
                  <td>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.rightsHolder"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.rightsHolder }}
                    </div>
                  </td>

                  <td style="width: 8%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.inventor"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.inventor }}
                    </div>
                  </td>
                  <td style="width: 8%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.status"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.status }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="
                handleSave(
                  26,
                  'printTestcqzm',
                  '申报项目所获知识产权证明目录表'
                )
              "
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'kyjl' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestkyjl"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                九、申报项目获得科研奖励情况表<span v-if="iss"
                  >：（仅限于本项目，请勿填写个人奖项）</span
                >
              </h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td>获奖项目名称</td>
                  <td>奖励名称及等级</td>
                  <td style="width: 16%">获奖时间</td>
                  <td style="width: 16%">授予机构</td>
                </tr>
                <tr
                  v-for="(
                    item, index
                  ) in forms.scientific_unit_through_project_application_data"
                  :key="index"
                >
                  <td style="width: 8%">{{ index + 1 }}</td>
                  <td>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.prjName"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.prjName }}
                    </div>
                  </td>
                  <td>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.level"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.level }}
                    </div>
                  </td>
                  <td style="width: 16%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.date"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.date }}
                    </div>
                  </td>
                  <td style="width: 16%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      placeholder=" "
                      v-model="item.grantingInstitution"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.grantingInstitution }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="
                handleSave(27, 'printTestkyjl', '申报项目获得科研奖励情况表')
              "
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'dbxlw' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestdbxlw"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                十、申报项目代表性论文表<span v-if="iss"
                  >(主要完成人为"第一作者"或"通讯作者"的论文)</span
                >
              </h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 16%">论文题目</td>
                  <td style="width: 16%">所有作者（通讯作者请标注*）</td>
                  <td style="width: 16%">期刊名称</td>
                  <td style="width: 16%">年份、卷期及页码</td>
                  <td style="width: 13%">被SCI、EI、ISTP收录情况</td>
                  <td style="width: 4%">影响因子</td>
                  <td style="width: 11%">他引次数</td>
                </tr>
                <tr
                  v-for="(
                    item, index
                  ) in forms.representative_thesis_application_project_data"
                  :key="index"
                >
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 16%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.title"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.title }}
                    </div>
                  </td>
                  <td style="width: 16%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.author"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.author }}
                    </div>
                  </td>
                  <td style="width: 16%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.journalName"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.journalName }}
                    </div>
                  </td>
                  <td style="width: 16%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.year"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.year }}
                    </div>
                  </td>
                  <td style="width: 13%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.sci"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.sci }}
                    </div>
                  </td>
                  <td style="width: 4%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.impactfactors"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.impactfactors }}
                    </div>
                  </td>
                  <td style="width: 11%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.times"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.times }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="handleSave(28, 'printTestdbxlw', '申报项目代表性论文表')"
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="print-warp"
      :class="editType == 'zdqk' && !showMenu ? 'show' : ''"
    >
      <div class="shenMain">
        <div class="bh" v-if="readOnly"></div>
        <div class="tabls">
          <div
            id="printTestzdqk"
            ref="imageWrapper"
            style="padding-top: 100px; flex-direction: column"
          >
            <div style="width: 80%">
              <h3 class="zzzzzz" style="text-align: center">
                十一、申报项目标准制定情况表
              </h3>
            </div>
            <table border="1" cellpading="0" cellspace="0">
              <tbody>
                <tr>
                  <td style="width: 8%">序号</td>
                  <td style="width: 20%">标准号</td>
                  <td style="width: 27%">标准名称</td>
                  <td style="width: 15%">类别</td>
                  <td style="width: 15%">颁布/修订时间</td>
                  <td style="width: 15%">起草人</td>
                </tr>
                <tr
                  v-for="(
                    item, index
                  ) in forms.development_project_standards_application_data"
                  :key="index"
                >
                  <td style="width: 8%">
                    {{ index + 1 }}
                  </td>
                  <td style="width: 20%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.standardNumber"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.standardNumber }}
                    </div>
                  </td>
                  <td style="width: 27%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.name"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.name }}
                    </div>
                  </td>
                  <td style="width: 15%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.type"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.type }}
                    </div>
                  </td>
                  <td style="width: 15%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.revisionTime"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.revisionTime }}
                    </div>
                  </td>
                  <td style="width: 15%">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder=" "
                      v-model="item.drafter"
                      v-if="iss"
                    >
                    </el-input>
                    <div style="text-align: left; padding: 5px" v-else>
                      {{ item.drafter }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="feng_btn">
            <el-button type="primary" @click="handleBack">返回</el-button>
            <el-button
              type="primary"
              @click="handleSave(29, 'printTestzdqk', '申报项目标准制定情况表')"
              v-if="!readOnly"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  upload_file,
  user_chile_details,
  innovation_success,
  innovation_select,
  award_find,
  upload_pdf,
  user_file,
  download_file,
  file_download,
  del_file,
} from "../../axios/api";
export default {
  props: {
    fromData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fromData: {
      handler: function (newUser) {
        this.forms = JSON.parse(JSON.stringify(newUser));
        if (this.forms.innovation_pdf != null) {
          this.url = this.forms.innovation_pdf;
          this.fileList.push(this.url);
          console.log(this.fileList, "   console.log(this.fileList)");
        }
      },
      deep: true, // 设置 deep 选项为 true，实现深度监听
    },
  },
  mounted() {
    this.forms = JSON.parse(JSON.stringify(this.fromData));
    if (this.forms.innovation_pdf != null) {
      this.url = this.forms.innovation_pdf;
      this.fileList.push(this.url);
      console.log(this.fileList, "   console.log(this.fileList)");
    }
    if (this.readOnly) {
      this.iss = false;
    }
  },
  data() {
    return {
      tabActive: "root", // 根目录
      subTitle: "", // 副标题
      iss: true,
      showMenu: true, // 是否展示目录
      editType: "", // 编辑类型
      forms: {
        app_project_three_years_benefits_data: {},
      },
      fileList: [],
      url: "",
    };
  },
  methods: {
    handleSavePdf(type) {
      let reqParams = {
        token: sessionStorage.getItem("Token"),
        id: this.forms.id,
        type: type + "",
      };
      reqParams.innovation_pdf = this.url;
      innovation_success(reqParams).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.forms.innovation_pdf = this.url
          this.$emit("update:fromData", this.forms);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleSave(type, idName, typeName) {
      this.iss = !this.iss;
      let reqParams = {
        token: sessionStorage.getItem("Token"),
        id: this.forms.id,
        type: type + "",
      };
      if (type == 20) {
        reqParams.innovation_pdf = this.url;
      } else if (type == 21) {
        reqParams.acceptance_report = this.forms.acceptance_report;
        reqParams.novelty_search_report = this.forms.novelty_search_report;
        reqParams.expert_evaluation_opinions =
          this.forms.expert_evaluation_opinions;
        reqParams.other = this.forms.other;
      } else if (type == 22) {
        reqParams.report_cooperate_situation =
          this.forms.report_cooperate_situation;
        reqParams.report_unit_cooperate_situation_experience =
          this.forms.report_unit_cooperate_situation_experience;
      } else if (type == 23) {
        reqParams.promotion_application_situation =
          this.forms.promotion_application_situation;
      } else if (type == 24) {
        reqParams.app_project_three_years_data = JSON.stringify(
          this.forms.app_project_three_years_data
        );
      } else if (type == 25) {
        reqParams.app_project_three_years_benefits_data = JSON.stringify(
          this.forms.app_project_three_years_benefits_data
        );
      } else if (type == 26) {
        reqParams.catalogue_intellectual_property_certificates_data =
          JSON.stringify(
            this.forms.catalogue_intellectual_property_certificates_data
          );
      } else if (type == 27) {
        reqParams.scientific_unit_through_project_application_data =
          JSON.stringify(
            this.forms.scientific_unit_through_project_application_data
          );
      } else if (type == 28) {
        reqParams.representative_thesis_application_project_data =
          JSON.stringify(
            this.forms.representative_thesis_application_project_data
          );
      } else if (type == 29) {
        reqParams.development_project_standards_application_data =
          JSON.stringify(
            this.forms.development_project_standards_application_data
          );
      }
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector(`#${idName}`),
          "创新成果奖",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);

          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form)
            .then((res) => {
              let params = {
                user_split_id: this.forms.id, //申报记录ID2
                path_url: res.data.url, //url
                type: reqParams.type,
                token: sessionStorage.getItem("Token"),
                type_name: typeName,
              };
              user_file(params).then((res) => {
                if (res.data.msg == "成功") {
                  innovation_success(reqParams).then((res) => {
                    if (res.data.code == 200) {
                      this.$message({
                        message: "保存成功",
                        type: "success",
                      });
                      this.$emit("update:fromData", this.forms);
                    } else {
                      this.$message.error(res.data.msg);
                    }
                  });
                } else {
                  this.btnLoading = false;
                  alert("保存失败，请重新下一步");
                }
              });
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    changeMenu(type, name) {
      console.log(type, name);
      this.tabActive = type;
      this.subTitle = name;
    },
    handleEdit(type) {
      this.showMenu = false;
      this.editType = type;
    },
    handleBack() {
      this.showMenu = true;
      this.editType = "";
      this.forms = { ...this.fromData };
    },
    ylan() {
      console.log(this.url, 'this.url')
      window.open(this.url);
    },
    xiaoxiabu() {
      let params = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: 20,
        user_data: this.url,
      };
      console.log(params);
      // return;
      innovation_success(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          // this.num = 3;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    uploadFile(item) {
      //我的项目要求formdata上传数据
      console.log(item.file);
      const extension = item.file.name.split(".")[1] === "pdf";
      const extension2 = item.file.name.split(".")[1] === "PDF";
      if (!extension && !extension2) {
        this.$message.error("上传模板只能是pdf格式!");
        return;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      console.log(item.file);
      const form = new FormData();
      form.append("file", item.file);
      //  form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          // this.arr.forEach((item, index) => {
          //   if (item.category == 1) {
          //   }
          // });
          console.log(res.data.url, 'res.data.url')
          this.url = res.data.url;
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    xiazaiModel() {
      // let p = {
      //   id: 7,
      // };
      // award_find(p).then((res) => {
      //   window.location.href = res.data.data.file_url;

      // });
      window.location.href = 'https://cjhwedang.oss-cn-beijing.aliyuncs.com/cjh/2024/2024创新成果奖文正.doc'
      // window.open('http://cjhwedang.oss-cn-beijing.aliyuncs.com/cjh/2024/2024创新成果奖文正.doc')
    },
    xiazai(idName, fileName) {
      this.iss = !this.iss;
      const loading = this.$loading({
        lock: true,
        text: "玩命加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector(`#${idName}`),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          loading.close();
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `https://2024pj.ciur.org.cn/common/2022/download_file?path=${res.data.url}&file_name=${fileName}`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.feng_btn {
  margin: 10px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
}
.cnt-title {
  font-size: 30px;
  margin: 80px 5% 80px 5%;
  display: flex;
  align-items: center;
  b {
    cursor: pointer;
  }
  span {
    color: #999;
  }
}
.box-card {
  width: 65%;
  margin: auto;
  margin-bottom: 100px;
  ::v-deep .el-card__body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .box-card-item {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    font-size: 26px;
    font-weight: 500;
    &.pitem {
      flex-direction: column;
      width: 100%;
      .title {
        width: 100%;
      }
    }
    .box-card-item-c {
      margin-left: 50px;
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dbdbdb;
      width: calc(100% - 50px);
      font-weight: 500;

      &:nth-child(1) {
        margin-top: 10px;
      }
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
.cnt-warp {
  overflow: hidden;
  width: 90%;
  margin: auto;
  .cnt-item {
    width: 18%;
    margin-right: 2.5%;
    margin-bottom: 2%;
    float: left;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .cnt-item-top {
      width: 100%;
      padding-top: 133.33%;
      height: 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .cnt-item-bottom {
      padding: 30px;
      display: flex;
      justify-content: space-around;
    }
  }
}
.cnt-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  width: 90%;
  color: #ffe400;
  letter-spacing: 2px;
}
.shangchuan {
  margin: 50px;
}
.shangchuan1 {
  margin: 50px;
}
.shangchuan > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<style scoped>
.print-warp {
  position: absolute;
  left: -10000px;
  top: -10000px;
  width: 100%;
}
.print-warp.show {
  position: relative;
  top: 0;
  left: 0;
}
.fengmiannew {
  width: 1200px;
  height: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 300px;
  align-items: center;
}
.fengmiannew > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmiannew > div:nth-child(1) > div {
  font-size: 40px;
}
.fengmiannew > div:nth-child(2) > div {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.fengmiannew > div:nth-child(2) > div > div:nth-child(1) {
  width: 240px;
  font-size: 22px;
}
.fengmiannew > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  font-size: 24px;
  border: 0px;
  border-bottom: 1px solid #999;
}
.shenMain {
  width: 97%;
  /* height: calc(100% - 100px); */
  height: auto;
  margin: 0 auto;
  position: relative;
}
.shenMain .bh {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.shenMain table {
  width: 1300px;
  margin: 20px 0;
  border-collapse: collapse;
    border-color: #000;
}
.shenMain tr {
  height: 75px;
}
.shenMain td {
  width: 150px;
  text-align: center;
  font-size: 24px;
}
.tabls input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 24px;
  width: 90px;
  min-height: 32px !important;
  line-height: 32px;
}
.tabls_bom {
  width: 100%;
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  justify-content: space-around;
}
.tabls_bom1 {
  width: 400px;
  display: flex;
  position: fixed;
  bottom: 20px;
  padding: 24px;
  left: 47%;
  box-sizing: border-box;
  justify-content: space-between;
}
.tabls_bom1 > div {
  width: 40%;
}
/* .tabls {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.tabls > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zunshou {
  display: flex;
  flex-direction: column;
  line-height: 1.8;
}
.qz-warp {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
}
.dwwarp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dwwarp input {
  width: auto;
}
input[type="checkbox"] {
  position: relative;
  width: 18px;
  height: 18px;
}
.table-textarea {
  width: 100%;
  outline: none;
  box-sizing: border-box;
}
::v-deep .yy-text .el-textarea__inner {
  padding-left: 10px;
  padding-right: 10px;
}
::v-deep .xy-top .el-textarea__inner {
  height: 600px;
}
::v-deep .xy-bottom .el-textarea__inner {
  height: 800px;
}
.p-xy-h {
  height: 1250px;
}
.fs24 {
  font-size: 24px;
}
</style>
